<template>
  <v-container>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan lainaa..."
      class="full-page-loader"
    ></full-page-loader>

    <v-form v-if="!loading" ref="form">
      <h3>Perustiedot</h3>

      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model="currentLoan.name"
            :rules="validations.required"
            label="Nimi"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-select
            v-model="currentLoan.method"
            :items="globalValues.loanMethods"
            item-text="text"
            item-value="val"
            :rules="validations.required"
            label="Lainan tyyppi"
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.startDate)"
                label="Noston päivämäärä"
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.startDate"
              first-day-of-week="1"
              @input="menu = false"
              :allowed-dates="allowedStartDates"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.totalAmount"
            type="number"
            step="0.01"
            min="1"
            label="Nostettu pääoma"
            :rules="validations.required.concat(validations.positiveNumber)"
            outlined
            dense
            suffix="€"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.currentAmount"
            type="number"
            step="0.01"
            label="Jäljellä oleva pääoma"
            outlined
            dense
            suffix="€"
            :rules="validations.positiveNumber"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.nextPaymentDate)"
                label="Seuraava maksupäivä"
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.nextPaymentDate"
              first-day-of-week="1"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.paymentPeriod"
            label="Lyhennysväli"
            :rules="validations.required.concat(validations.positiveNumber)"
            suffix="kk"
            outlined
            dense
            v-prevent-paste
            v-only-numbers
          ></v-text-field>
        </v-col>

        <v-col
          v-if="currentLoan.method == 'annuity' || currentLoan.method == 'equal instalment'"
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <v-text-field
            v-model.number="currentLoan.numOfInst"
            suffix="kpl"
            label="Maksuerien kokonaismäärä"
            :rules="validations.required.concat(validations.positiveNumber)"
            outlined
            dense
            v-prevent-paste
            v-only-numbers
          ></v-text-field>
        </v-col>

        <v-col v-if="currentLoan.method == 'fixed annuity'" cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.annuity"
            type="number"
            step="0.01"
            min="1"
            label="Maksuerän kokonaissumma €"
            :rules="validations.required.concat(validations.positiveNumber)"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <h3>Korot / kulut</h3>

      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.organizationalCost"
            type="number"
            min="0.01"
            step="0.01"
            label="Järjestelykulu"
            suffix="€"
            outlined
            dense
            :rules="validations.zeroOrGreater"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.handlingCostPerInst"
            type="number"
            min="0.01"
            step="0.01"
            suffix="€"
            label="Käsittelykulu per maksukerta"
            outlined
            dense
            :rules="validations.zeroOrGreater"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.margin"
            type="number"
            step="0.01"
            min="0.01"
            max="50"
            label="Pankin marginaali"
            suffix="%"
            outlined
            dense
            :rules="validations.zeroOrGreater"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-select
            v-model="currentLoan.refRateType"
            :items="globalValues.loanTypes"
            item-text="text"
            item-value="val"
            :rules="validations.required"
            label="Viitekoron tyyppi"
            outlined
            dense
            @change="currentLoan.curRefRateIntStart = ''"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-text-field
            v-model.number="currentLoan.curRefRateInt"
            type="number"
            step="0.001"
            max="50"
            label="Voimassa oleva viitekorko"
            suffix="%"
            outlined
            dense
            :rules="validations.anyNumber"
          ></v-text-field>
        </v-col>

        <v-col
          v-if="currentLoan.refRateType && currentLoan.startDate"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.curRefRateIntStart)"
                label="Voimassa olevan korkojakson alkamispäivä"
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.curRefRateIntStart"
              first-day-of-week="1"
              @input="menu2 = false"
              :allowed-dates="allowedDatesRefRate"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-btn color="info" class="mr-4 mt-5" @click="submit">{{ submitBtnText }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import FullPageLoader from "@/components/FullPageLoader";
import { mapState, mapMutations } from "vuex";
import globalValues from "../../configs/globalValues";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    FullPageLoader,
  },

  props: {
    loan: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submitBtnText: { type: String, default: "" },
  },

  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      currentLoan: {
        name: "",
        method: "",
        startDate: "",
        totalAmount: null,
        currentAmount: null,
        numOfInst: null,
        handlingCostPerInst: null,
        organizationalCost: null,
        refRateType: "",
        curRefRateInt: null,
        curRefRateIntStart: "",
        margin: null,
        nextPaymentDate: "",
        paymentPeriod: null,
        annuity: null,
      },
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("loan", ["loading"]),
  },

  watch: {
    loan: function (val) {
      this.currentLoan = val || {};
      this.setLoading(false);
    },
  },

  methods: {
    ...mapMutations("loan", ["setLoading"]),

    async submit() {
      if (this.$refs.form.validate()) {
        if (this.numOfInstIsCorrect()) {
          if (this.checkRefRateIntDate()) {
            if (this.checkNextPaymentDate()) {
              this.$emit("sendloan", this.currentLoan);
            } else {
              return this.showPopup(
                `Seuraava maksupäivä ei vastaa noston päivämäärää ja lyhennysväliä.`,
                "error"
              );
            }
          } else {
            return this.showPopup(
              `Korontarkistuspäivä ei vastaa noston päivämäärää ja korkotyyppiä.`,
              "error"
            );
          }
        } else {
          const length = this.roundDecimals(
            (this.currentLoan.numOfInst * this.currentLoan.paymentPeriod) / 12
          );
          return this.showPopup(
            `Maksuerien määrä ja lyhennysväli eivät vastaa toisiaan. Laina ei voi olla ${length} vuoden mittainen.`,
            "error"
          );
        }
      }
    },

    numOfInstIsCorrect() {
      if (this.currentLoan.method != "fixed annuity") {
        const expectedNumOfInst = 12 / this.currentLoan.paymentPeriod;
        const tolerance = 1e-10;

        if (Math.abs(this.currentLoan.numOfInst % expectedNumOfInst) < tolerance) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    checkRefRateIntDate() {
      const startDateMoment = moment(moment(this.currentLoan.startDate).format("YYYY-MM-DD"));
      const refRateIntMoment = moment(
        moment(this.currentLoan.curRefRateIntStart).format("YYYY-MM-DD")
      );

      const refRateIntWithoutYear = moment(refRateIntMoment).format("MM-DD");

      let months;
      switch (this.currentLoan.refRateType) {
        case "euribor 3":
          months = 3;
          break;
        case "euribor 6":
          months = 6;
          break;
        case "euribor 12":
          months = 12;
          break;
        default:
          break;
      }

      const dynamicDates = [];
      for (let i = 0; i < 12; i += months) {
        dynamicDates.push(startDateMoment.add(i, "months").format("MM-DD"));
      }

      return dynamicDates.includes(refRateIntWithoutYear);
    },

    checkNextPaymentDate() {
      const startDateMoment = moment(moment(this.currentLoan.startDate).format("YYYY-MM-DD"));
      const nextPaymentDate = moment(moment(this.currentLoan.nextPaymentDate).format("YYYY-MM-DD"));

      const nextPaymentDateWithoutYear = moment(nextPaymentDate).format("MM-DD");

      let months = this.currentLoan.paymentPeriod;

      const dynamicDates = [];
      for (let i = 0; i < 12; i += months) {
        const currentDate = moment(startDateMoment).add(i, "months").format("MM-DD");
        dynamicDates.push(currentDate);
      }

      return dynamicDates.includes(nextPaymentDateWithoutYear);
    },

    allowedStartDates(val) {
      const today = moment().format("YYYY-MM-DD");

      if (moment(val).isSameOrBefore(today)) return true;
      else return false;
    },

    allowedDatesRefRate(val) {
      const today = moment().format("YYYY-MM-DD");
      const startDate = moment(this.currentLoan.startDate).format("YYYY-MM-DD");

      const refRateType = this.currentLoan.refRateType;
      let lastPossibleDate;

      if (refRateType == "euribor 12") {
        lastPossibleDate = moment(today).subtract(12, "months").format("YYYY-MM-DD");
      } else if (refRateType == "euribor 6") {
        lastPossibleDate = moment(today).subtract(6, "months").format("YYYY-MM-DD");
      } else if (refRateType == "euribor 3") {
        lastPossibleDate = moment(today).subtract(3, "months").format("YYYY-MM-DD");
      }

      if (
        moment(val).isSameOrAfter(lastPossibleDate) &&
        moment(val).isSameOrAfter(startDate) &&
        moment(val).isSameOrBefore(today)
      )
        return true;
      else return false;
    },
  },
};
</script>

<style scoped></style>
